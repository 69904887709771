import axios from "axios";
import { endAndHome } from "components/molecules/navbar";
import { _HOST } from "config/constant/endpoints";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Image, Row, Stack } from "react-bootstrap";
import { io } from "socket.io-client";
import useSocket from "utilities/hooks/use-socket";



function Resume () {
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");


    const [srcImage, setSrcImage] = useState("")
    const [data, setData] = useState(
      JSON.parse(localStorage.getItem("rtgs-form-1")!)
    );
    useEffect(() => {
        // const myTimeout = setTimeout(() => {
            // if(srcImage === ""){
      const rekeningSumberdana = data[3].split("-")[0]
      const namaPemilik = data[3].split("-")[1]
      const jenisLayanan = data[9]
      const bankPenerima = data[5]
      const rekeningTujuan = data[6]
      const namaPenerima = data[7]
      // const alamtPengirim = JSON.parse("porto")["alamat"]
      const jenisPenduduk = "Penduduk"
      const nominal = data[8]
      const berita = data[12] !== '' || data[12] !== ' ' ? data[12] : " "
      console.log("uri", `${_HOST}/api/form/resume-rtgs/${jenisLayanan.toLowerCase() === "rtgs"?3:5}-${jenisLayanan.toLowerCase() === "rtgs"?" ":jenisLayanan}-2103212-${jenisPenduduk === "Penduduk"?true:false}-${namaPemilik}-JL MAHAKAM 015 006 RAMBUTAN CIRACAS KOTA JAKARTA TIMUR DKI JAKARTA-089540303096-${rekeningSumberdana}-${nominal}-2500-${namaPenerima}-${data[16]}-${bankPenerima}-${rekeningTujuan}-${berita}-Hadi`)
                axios.get(`${_HOST}/api/form/resume-rtgs/${jenisLayanan.toLowerCase() === "rtgs"?3:5}-${jenisLayanan.toLowerCase() === "rtgs"?" ":jenisLayanan}-2103212-${jenisPenduduk === "Penduduk"?true:false}-${namaPemilik}-JL MAHAKAM 015 006 RAMBUTAN CIRACAS KOTA JAKARTA TIMUR DKI JAKARTA-089540303096-${rekeningSumberdana}-${nominal}-2500-${namaPenerima}-${data[16]}-${bankPenerima}-${rekeningTujuan}-${berita}-Hadi`).then(result => {
                    // clearTimeout(myTimeout)
                    console.log(result.data )
                    setSrcImage(() => result.data.image)
                    console.log(result.data.image, srcImage)
                }).catch(err => console.log(err.message))
        //     }
            
        // }, 10000)
        moment.locale("id");
    
        const socket = io(_HOST);
        socket.on("connect", onConnect);
        socket.emit("call", "join", { room: session[1] });
        socket.on("conferencing", (...arg: any) => {
          console.log(arg);
          const isProceed = arg[arg.length - 1];
        //   setOTP(arg[1]);
          if (arg[0] === 20 && arg[4]) {
            // setData(JSON.parse(localStorage.getItem(`rtgs-form-${arg[1]}`)!));
            // setAllPage(arg[2]);
            console.log("uri2", `${_HOST}${arg[4]}`)
            axios.get(`${_HOST}${arg[4]}`).then(result => {
                // clearTimeout(myTimeout)
                setSrcImage(() => result.data.image)
                console.log(result.data.image, srcImage)
            }).catch(err => console.log(err.message))
          }
          if (arg[0] === 20 && arg[1]) {
        setData(JSON.parse(localStorage.getItem(`rtgs-form-${arg[1]}`)!));
        // setAllPage(arg[2]);
      }
      
      if (arg[0] === 99) {
        endAndHome(JSON.parse(localStorage.getItem("zoom-client")!));
      }
          if (arg[0] === 99) {
            endAndHome(JSON.parse(localStorage.getItem("zoom-client")!));
          }
        });
    
        return () => {
          socket.off("connect");
          socket.off("conferencing");
          socket.close();
        };
      }, []);
    // useEffect(() => {
    //     axios.get(`${_HOST}/api/form/resume-rtgs/3-%20-908726-true-hadhud%20kamran-beji-4561234-87654-300000-5000-esa-jl,%20kampung-mandiri%20se-1234-makan-ivan`).then(result => {
    //         setSrcImage(() => result.data.image)
    //         console.log(result.data.image, srcImage)
    //     }).catch(err => console.log(err.message))
    // },[])
    return (<Row>
      <Col>
      <Stack gap={2} style={{textAlign:"center"}}>
            <h2>Selamat</h2>
            <h3 style={{color:"#22d822"}}>Transaksi Anda Berhasil</h3>
            {srcImage ? <Image src={`data:image/png;base64,${srcImage}`} style={{width:"90%", alignSelf:"center", marginTop:"5vh"}}/>: ""}
        </Stack>
      </Col>
        
        
    </Row>)
}

export default Resume