import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Home = lazy(() => import("../pages/home"));
const Layout = lazy(() => import("../pages/layout"));
const Login = lazy(() => import("../pages/login"));
const MeetingRoom = lazy(() => import("../pages/conversation"));
const Tes = lazy(
  () => import("../pages/conversation/features/rtgs/newResume")
);
const ProtectedRoute = lazy(() => import("../pages/protected-route"));

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/tes" element={<Tes />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route index element={<Home />} />
        <Route path="conversation" element={<MeetingRoom />} />
        {/* <Route path="tes" element={<Tes />} /> */}
        {/* <Route path="log" element={<Tes />} /> */}
      </Route>
    </Routes>
  );
  // return useRoutes([
  //   {
  //     path: '/',
  //     element: <Layout/>,
  //     children: [
  //       {
  //         element: <Home />,
  //         index: true,
  //       },
  //       {
  //         path: 'conversation',
  //         element: <MeetingRoom />
  //       }
  //     ]
  //   },
  //   {
  //     path:'/login',
  //     element: <Login />
  //   }
  // ])
}

export default App;
